import React from 'react';

const BlogPage = props => {

    return (
        <div className="blog-main">

        </div>
    )
}

export default BlogPage;